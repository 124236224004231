// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-tsx": () => import("/usr/local/nginx/html/other_domains/strangemusichollywood/src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("/usr/local/nginx/html/other_domains/strangemusichollywood/src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-gallery-tsx": () => import("/usr/local/nginx/html/other_domains/strangemusichollywood/src/pages/gallery.tsx" /* webpackChunkName: "component---src-pages-gallery-tsx" */),
  "component---src-pages-index-tsx": () => import("/usr/local/nginx/html/other_domains/strangemusichollywood/src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-rates-tsx": () => import("/usr/local/nginx/html/other_domains/strangemusichollywood/src/pages/rates.tsx" /* webpackChunkName: "component---src-pages-rates-tsx" */)
}

exports.data = () => import("/usr/local/nginx/html/other_domains/strangemusichollywood/.cache/data.json")

